// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	runInAction,
	toJS,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcIsArray,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	kpis: null,
};

let app = {};

export class KpisStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.KPIS);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	kpis = _default.kpis;

	@computed
	get current_kpis() {
		return this.kpis ? toJS(this.kpis) : null;
	}

	@computed
	get current_ongoing_kpis() {
		return this.kpis?.ongoing ? toJS(this.kpis.ongoing) : null;
	}

	@computed
	get current_completed_kpis() {
		return this.kpis?.completed ? toJS(this.kpis.completed) : null;
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Calculating KPIs',
		};
	}

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@action
	index = () => {
		this.setLoading(true);

		return app.store.api.kpis
			.index()
			.then((response) => {
				this.set(KEYS.KPIS, response);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle KPI's`,
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default KpisStore;
