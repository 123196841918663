// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	toJS,
	runInAction,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsArray,
	AcIsNull,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
	AcDownloadFile,
} from '@utils';

const _default = {
	options: {},
	files: null,
};

let app = {};

export class FilesStore {
	constructor(store) {
		makeObservable(this);

		app.store = store;
	}

	@observable
	files = _default.files;

	@computed
	get current_files() {
		if (!AcIsSet(this.files) || !AcIsArray(this.files) || this.files.length === 0)
			return null;

		const sortedByUploadedDate = this.files.slice().sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at);
		});

		return toJS(sortedByUploadedDate);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || '',
		};
	}

	@observable
	busy = {
		status: false,
		message: null,
	};

	@computed
	get is_busy() {
		return toJS(this.busy.status);
	}

	@action
	setBusy(state, message) {
		this.busy = {
			status: state || false,
			message: message || '',
		};
	}

	@action
	index = () => {
		this.setLoading(true);

		return app.store.api.files
			.index()
			.then((response) => {
				const { data } = response;
				this.set(KEYS.FILES, data);

				this.setLoading(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle documenten`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	download = async (item, type = 'files') => {
		if (!AcIsSet(item?.id)) return;
		if (!AcIsSet(item?.name)) return;

		this.setBusy(true);

		const toast = await app.store.toasters.add({
			variant: 'download',
			title: {
				line: `Het bestand "<strong>{{filename}}</strong>" wordt opgehaald`,
				arguments: { filename: item.name },
			},
			indeterminate: true,
		});

		return app.store.api.files
			.download(item.id, type)
			.then((response) => {
				let filename = item.name;
				let filetype = item.mimetype;

				if (
					!AcIsSet(filename) &&
					AcIsSet(response?.headers?.['content-disposition'])
				) {
					filename = response?.headers['content-disposition']?.split('filename=')[1];
					filename = filename.replace(/['"]+/g, '');
				}

				if (AcIsSet(response?.headers?.['content-type'])) {
					filetype = response?.headers['content-type'];
				}

				AcDownloadFile(response.data, filename, filetype);

				app.store.toasters.update(toast.id, {
					title: 'Download voltooid',
					description: {
						line: `Het bestand "<strong>{{filename}}</strong>" is gedownload`,
						arguments: { filename },
					},
					variant: 'success',
					indeterminate: false,
				});

				this.setBusy(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.update(toast.id, {
					title: `Er is iets fout gegaan tijdens het downloaden van het bestand`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
					variant: 'error',
					indeterminate: false,
				});

				this.setBusy(false);
				// throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default FilesStore;
