// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	runInAction,
	toJS,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcIsArray,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	faq: [],
};

let app = {};

export class FaqStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.FAQ);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	faq = _default.faq;

	@computed
	get current_faq() {
		if (!AcIsSet(this.faq) || !AcIsArray(this.faq) || this.faq.length === 0)
			return null;

		return toJS(this.faq);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Gathering questions & answers',
		};
	}

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@action
	index = () => {
		this.setLoading(true);

		return app.store.api.faq
			.index()
			.then((response) => {
				const { data } = response;
				this.set(KEYS.FAQ, data);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle vragen en antwoorden`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default FaqStore;
