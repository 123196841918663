// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	runInAction,
	toJS,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcIsArray,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
	AcBase64ToArrayBuffer,
	AcDownloadFile,
} from '@utils';

const _default = {
	options: {},
	updates: [],
};

let app = {};

export class ProjectUpdatesStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.UPDATES);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	updates = _default.updates;

	@computed
	get current_updates() {
		if (
			!AcIsSet(this.updates) ||
			!AcIsArray(this.updates) ||
			this.updates.length === 0
		)
			return null;

		return toJS(this.updates);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading = (state, message) => {
		this.loading = {
			status: state || false,
			message: message || 'Gathering all projectupdates',
		};
	};

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@observable
	busy = {
		status: false,
		message: null,
	};

	@action
	setBusy = (state, message) => {
		this.busy = {
			status: state || false,
			message: message || 'Gathering all projectupdates',
		};
	};

	@computed
	get is_busy() {
		return toJS(this.busy.status);
	}

	@action
	index = () => {
		this.setLoading(true);

		return app.store.api.project_updates
			.index()
			.then((response) => {
				const { data } = response;
				this.set(KEYS.UPDATES, data);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle project updates`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	download = async (item) => {
		if (!AcIsSet(item?.id)) return;
		if (!AcIsSet(item?.name)) return;

		this.setBusy(true);

		const toast = await app.store.toasters.add({
			variant: 'download',
			title: `Het bestand wordt opgehaald`,
			indeterminate: true,
		});

		return app.store.api.project_updates
			.download(item.id)
			.then((response) => {
				let filename = [];
				if (AcIsSet(item.name)) filename.push(item.name);
				if (AcIsSet(item.title)) filename.push(item.title);
				if (AcIsSet(item.id)) filename.push(item.id);
				filename = filename.join('_');
				filename = `${filename}.pdf`;

				AcDownloadFile(AcBase64ToArrayBuffer(response.data), filename, item.type);

				app.store.toasters.update(toast.id, {
					title: 'Download voltooid',
					description: {
						line: `Het bestand "<strong>{{filename}}</strong>" is gedownload`,
						arguments: { filename },
					},
					variant: 'success',
					indeterminate: false,
				});

				this.setBusy(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.update(toast.id, {
					title: `Er is iets fout gegaan tijdens het downloaden van het bestand`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
					variant: 'error',
					indeterminate: false,
				});

				this.setBusy(false);
				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default ProjectUpdatesStore;
