// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	runInAction,
	toJS,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Models
import { Project } from '@models/ac-project.model';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcIsArray,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcGetState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
	AcMemoize,
} from '@utils';

const _default = {
	options: {},
	projects: [],
	project: {},
	introductions: [],
};

let app = {};

export class ProjectsStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.PROJECTS);
		AcAutoLoad(this, KEYS.PROJECT);
		AcAutoLoad(this, KEYS.INTRODUCTIONS);
		AcAutoLoad(this, KEYS.UPDATES);
		AcAutoLoad(this, KEYS.FILTERS);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	projects = _default.projects;

	@observable
	project = _default.project;

	@observable
	introductions = _default.introductions;

	@computed
	get current_projects() {
		if (
			!AcIsSet(this.projects) ||
			!AcIsArray(this.projects) ||
			this.projects.length === 0
		)
			return null;

		return toJS(this.projects);
	}

	@computed
	get current_number_of_projects() {
		return toJS(this.current_projects?.length);
	}

	@computed
	get has_projects() {
		return toJS(this.current_number_of_projects > 0);
	}

	@computed
	get current_recent_projects() {
		/*
			recent projects: return the (max) 3 most recent projects
		*/
		if (!this.has_projects) return null;
		return toJS(this.current_projects.slice(0, 3));
	}

	@computed
	get current_project() {
		return toJS(this.project);
	}

	@computed
	get current_introductions() {
		if (
			!AcIsSet(this.introductions) ||
			!AcIsArray(this.introductions) ||
			this.introductions.length === 0
		)
			return null;

		return toJS(this.introductions);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Gathering all projects',
		};
	}

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@observable
	filters = AcGetState('filter') || {
		status: KEYS.ONGOING, // or KEYS.COMPLETED
		// status: KEYS.COMPLETED, // or KEYS.ONGOING
	};

	@computed
	get current_filters() {
		return toJS(this.filters);
	}

	@action
	index = () => {
		this.setLoading(true);

		const filter = this.filters;

		return app.store.api.projects
			.index({ filter })
			.then((response) => {
				const { data } = response;

				const collection = data.map((n) => new Project(n));
				this.set(KEYS.PROJECTS, collection);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle projecten`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	show = (id) => {
		this.setLoading(true);

		return app.store.api.projects
			.show(id)
			.then(async (response) => {
				const { data } = response;

				const project_kpis = await this.get_kpis(id);

				const parsed = new Project({ ...data, project_kpis });

				this.set(KEYS.PROJECT, parsed);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van het project met ID ${id}`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	get_kpis = (id) => {
		return app.store.api.projects
			.kpis(id)
			.then((response) => {
				const { data } = response;
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van het project met ID ${id}`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	get_updates = () => {
		this.setLoading(true);

		return app.store.api.projects
			.updates()
			.then((response) => {
				const { data } = response;
				this.set(KEYS.UPDATES, data);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle project updates`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	get_introductions = () => {
		return app.store.api.projects
			.introductions()
			.then((response) => {
				const { data } = response;

				this.set(KEYS.INTRODUCTIONS, data);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van project introducties`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default ProjectsStore;
