// Imports => MOBX
import { observable, computed, action, reaction, makeObservable, toJS } from 'mobx';
import i18n from '@config/i18n';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	profile: {},
};

let app = {};

export class ProfileStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.PROFILE);
		AcAutoLoad(this, KEYS.LOCALE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	profile = _default.profile;

	@observable
	locale = _default.locale;

	@computed
	get current_profile() {
		return toJS(this.profile);
	}

	@computed
	get current_language() {
		return toJS(this.profile?.language || this.locale);
	}

	@computed
	get current_accounts() {
		if (!this.current_profile?.accounts?.length) return null;
		return toJS(this.current_profile?.accounts || []);
	}

	@computed
	get current_account() {
		if (!AcIsSet(this.current_profile?.preferred_account)) {
			return {
				id: null,
				name: 'Alle accounts',
			};
		}

		return toJS(this.current_profile?.preferred_account);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@observable
	busy = {
		status: false,
		message: null,
	};

	@action
	setBusy(state, message) {
		this.busy = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@computed
	get is_busy() {
		return toJS(this.busy.status);
	}

	@action
	who_am_i = () => {
		this.setLoading(true);

		return app.store.api.profile
			.whoami()
			.then(async (response) => {
				this.set(KEYS.PROFILE, response);

				await AcSaveState(KEYS.LOCALE, response?.language);
				await i18n.changeLanguage(response?.language);

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van jouw profiel en instellingen`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	update_language = (data) => {
		this.setBusy(true);

		return app.store.api.profile
			.update_language(data)
			.then((response) => {
				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: `Taalvoorkeuren zijn opgeslagen`,
				});

				AcSaveState(KEYS.LOCALE, data?.language);

				this.setBusy(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het wijzigen van taalvoorkeuren`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setBusy(false);
				throw error;
			});
	};

	// Automagically change i18n language
	changeLanguageReaction = reaction(
		() => this.current_profile?.language,
		async (language) => {
			await i18n.changeLanguage(language);
			this.setLoading(false);
		}
	);

	@action
	update_account = (account) => {
		this.setBusy(true);

		return app.store.api.profile
			.update_account(account)
			.then((response) => {
				this.setBusy(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: 'Wisselen van account is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});
				this.setBusy(false);
				throw error;
			});
	};

	@action
	update_password = (data) => {
		this.setBusy(true);

		return app.store.api.profile
			.update_password(data)
			.then((response) => {
				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: `Jouw wijzigingen zijn opgeslagen`,
				});

				this.setBusy(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het wijzigen van jouw profiel en instellingen`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setBusy(false);
				throw error;
			});
	};

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default ProfileStore;
